export default [
  {
    title: 'Anasayfa',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    route: 'Customers',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'CustomerAdd',
        resource: 'ADMIN_CUSTOMER_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Customers',
        resource: 'ADMIN_CUSTOMER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Görüşmeler',
    route: 'Interviews',
    icon: 'MessageCircleIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'InterviewCustomerSearch',
        resource: 'ADMIN_INTERVIEW_ADD',
        action: 'read',
      },
      {
        title: 'Görüşme Listesi',
        route: 'Interviews',
        resource: 'ADMIN_INTERVIEW_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Hatırlatmalar',
    route: 'Reminders',
    icon: 'BellIcon',
    children: [
      {
        title: 'Hatırlatmalarım',
        route: 'Reminders',
        resource: 'ADMIN_REMINDER_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Teklifler',
    route: 'Offers',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'OfferCustomerSearch',
        resource: 'ADMIN_OFFER_ADD',
        action: 'read',
      },
      {
        title: 'Açık Teklifler',
        route: 'OpenOffers',
        resource: 'ADMIN_OFFER_LIST',
        action: 'read',
      },
      {
        title: 'Tüm Teklifler',
        route: 'Offers',
        resource: 'ADMIN_OFFER_LIST',
        action: 'read',
      },
      {
        title: 'Teklif Talepleri',
        route: 'OfferRequests',
        resource: 'ADMIN_OFFER_REQUESTS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kataloglar',
    route: 'Catalogs',
    icon: 'BookIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'CatalogCustomerSearch',
        resource: 'ADMIN_CATALOGS_ADD',
        action: 'read',
      },
      {
        title: 'Kataloglar',
        route: 'Catalogs',
        resource: 'ADMIN_CATALOGS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Mesajlar',
    route: 'Messages',
    icon: 'MessageSquareIcon',
    children: [
      {
        title: 'Mesaj Listesi',
        route: 'Messages',
        resource: 'ADMIN_MESSAGES_LIST',
        action: 'read',
      },
      {
        title: 'Mesaj Arşivi',
        route: 'MessageArchive',
        resource: 'ADMIN_MESSAGES_LIST',
        action: 'read',
      },
    ],
  },
  {
    header: 'Yönetim',
    resource: 'ADMIN',
    action: 'read',
  },
  {
    title: 'Ürünler',
    route: 'Products',
    icon: 'LayersIcon',
    children: [
      {
        title: 'Ürün Listesi',
        route: 'Products',
        resource: 'ADMIN_PRODUCT_LIST',
        action: 'read',
      },
      {
        title: 'Ürün Grupları',
        route: 'ProductGroups',
        resource: 'ADMIN_PRODUCT_LIST',
        action: 'read',
      },
      {
        title: 'Kalite Adı',
        route: 'ProductQualities',
        resource: 'ADMIN_PRODUCT_QUALITY_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Fiyat Listesi',
    route: 'Prices',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'PriceCustomerSearch',
        resource: 'ADMIN_PRICE_ADD',
        action: 'read',
      },
      {
        title: 'Listeler',
        route: 'Prices',
        resource: 'ADMIN_PRICE_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Müşteri Hesapları',
    route: 'CustomerAccounts',
    icon: 'UserCheckIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'AccountsCustomerSearch',
        resource: 'ADMIN_CUSTOMER_ACCOUNT_ADD',
        action: 'read',
      },
      {
        title: 'Hesap Listesi',
        route: 'CustomerAccounts',
        resource: 'ADMIN_CUSTOMER_ACCOUNT_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Raporlar',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Görüşmeler',
        route: 'ReportInterviews',
        resource: 'ADMIN_REPORTS_INTERVIEW',
        action: 'read',
      },
      {
        title: 'Teklifler',
        route: 'ReportOffers',
        resource: 'ADMIN_REPORTS_OFFER',
        action: 'read',
      },
      {
        title: 'Kataloglar',
        route: 'ReportCatalogs',
        resource: 'ADMIN_REPORTS_CATALOGS',
        action: 'read',
      },
      {
        title: 'Müşteri Karnesi',
        route: 'ReportCustomers',
        resource: 'ADMIN_REPORTS_CUSTOMERS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    route: '#',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Ülke',
        route: 'Countries',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Şehir',
        route: 'Cities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Temas Tipi',
        route: 'Meets',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Sektör',
        route: 'Sectors',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Faaliyet Alanları',
        route: 'Activities',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'İlgili Kişi Ünvanları',
        route: 'RelatedPersonTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Para Birimleri',
        route: 'Currencies',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Birim Tipleri',
        route: 'Units',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Müşteri Tipleri',
        route: 'CustomerTypes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Teklif Özel Şartları',
        route: 'OfferTerms',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Teklif Durumları',
        route: 'OfferStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Katalog Durumları',
        route: 'CatalogStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görüşme Konuları',
        route: 'InterviewSubjects',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Görüşme Durumları',
        route: 'InterviewStatuses',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
      {
        title: 'Vergi Oranları',
        route: 'Taxes',
        resource: 'ADMIN_DEFINES_ALL',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: '#',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
      {
        title: 'Firma Bilgileri',
        route: 'ConfigCompany',
        resource: 'ADMIN_COMPANY_CONFIG',
        action: 'read',
      },
    ],
  },
]
